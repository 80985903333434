<template>
  <k-page>
    <k-navigation class="page__main--start"></k-navigation>
    <k-heading class="page__header">khattab.nl</k-heading>
    <div class="page__main--end"></div>
    <div class="page__full">Coming soon</div>
    <div class="page__aside--start"></div>
    <div class="page__main"></div>
    <div class="page__aside--end">©2019-{{ year }} khattab.nl</div>
  </k-page>
</template>

<script>
import KHeading from "@/components/KHeading";
import KPage from "@/components/KPage";
import KNavigation from "@/components/KNavigation";

export default {
  components: { KNavigation, KHeading, KPage },

  name: "home-view",
  data() {
    return {
      title: "Home",
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss"></style>
