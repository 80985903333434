<template>
  <menu class="menu">
    <div class="menu__item" v-for="route in routes" :key="route">
      <router-link :to="{ name: route }" class="menu__link">{{
        route
      }}</router-link>
    </div>
  </menu>
</template>

<script>
export default {
  name: "k-navigation",
  data() {
    return {
      routes: ["home"],
    };
  },
};
</script>

<style lang="scss">
@import "../scss/variables.scss";

.menu {
  margin: 0;
}

.menu__item {
  padding: 0;
  margin: 0;
}

.menu__link {
  &:visited {
    color: black;
  }
}
</style>
