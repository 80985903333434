<template>
  <header class="heading">
    <h1><slot></slot></h1>
  </header>
</template>

<script>
export default {
  name: "k-heading",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "../scss/variables.scss";

.heading {
  writing-mode: vertical-rl;
}
</style>
