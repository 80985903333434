<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import "scss/variables.scss";
@import "scss/reset.scss";

@import url("https://fonts.googleapis.com/css?family=Ubuntu");
</style>
