<template>
  <div class="page">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "k-page",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "../scss/variables.scss";

@mixin page-section() {
  border-width: 0 2px 1px 0;
  border-color: black;
  border-style: solid;
  padding: 2px;
  border-radius: 16px / 1px;
}

.page {
  margin: 1rem;
  display: grid;
  transform: rotate(-1deg);
  grid-gap: 1rem;
  grid-template-columns:
    [full-start] minmax(1rem, 1fr)
    [main-start] auto [header-start] auto [header-end] auto [main-end] minmax(
      1rem,
      1fr
    )
    [full-end];
}

.page__full {
  @include page-section();

  grid-column: full;
  text-align: center;
}

.page__header {
  @include page-section();

  grid-column: header;
}

.page__main {
  @include page-section();

  grid-column: main;
  text-align: center;
}

.page__main--start {
  @include page-section();

  grid-column: main-start / header-start;
  text-align: start;
}

.page__main--end {
  @include page-section();

  grid-column: header-end / main-end;
  text-align: end;
}

.page__aside--start {
  @include page-section();

  grid-column: full-start / main-start;
  text-align: end;
}

.page__aside--end {
  @include page-section();

  grid-column: main-end / full-end;
  text-align: start;
}
</style>
